import React, { useEffect, useRef, useState } from 'react'
import bannerImg from "../../../../assets/banner-img/banner_home.webp"
import { PopupModel } from '../../../model/PopupModel' 


export const BannerHome = () => {
  const [ShowPopup, setShowPopup] = useState(false)
  const [isInView, setIsInView] = useState(false);
  const bannerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
        }
      },
      { threshold: 0.1 } // Loads when 10% of the banner is in view
    );

    if (bannerRef.current) {
      observer.observe(bannerRef.current);
    }

    return () => {
      if (bannerRef.current) {
        observer.unobserve(bannerRef.current);
      }
    };
  }, []);
  
  return (
    <div
      ref={bannerRef}
      className="banner w-full bg-[#040406] flex items-center justify-center"
      style={isInView ? { backgroundImage: `url(${bannerImg})` } : {}}
    >
      <div className="container max-w-[1240px] px-4 flex items-center justify-between">
        <div className="banner-content z-10">
          <h1 className="banner-h1">
            Invest in Dubai real estate with DNK Real Estate
          </h1>
          <p className="pb-4">
            Our agents will help you find the right property
          </p>
          <button onClick={() => setShowPopup(true)} className="site-btn ">
            Request callback
          </button>
        </div>
      </div>
      <div className="bg-[#00000066] w-full h-full absolute left-0 top-0 z-0 sm:hidden"></div>
      <div>
        {ShowPopup && <PopupModel onClose={() => setShowPopup(false)} />}
      </div>
    </div>
  );
}

export default BannerHome